const normalizeRevenusDataTable = periodDatas => {
  delete periodDatas["total"];
  delete periodDatas["debugQuery"];
  let results = [];

  for (var key in periodDatas) {
    const {
      date,
      paidPlay,
      organicPlay,
      paidImpression,
      earning
    } = periodDatas[key];

    results.push({
      date,
      est_earnings: new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: "EUR"
      }).format((earning?.total || 0).toFixed(2)),
      impressions: paidImpression?.total || 0,
      paid_listens: paidPlay?.total || 0,
      organic_listens: organicPlay?.total || 0
    });
  }
  return results;
};

export const tableFields = [
  {
    key: "program.name",
    label: "Name",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-left align-middle",
    isAdmin: false
  },
  {
    key: "earning.total",
    label: "Est. Earnings",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-left align-middle",
    formatter: (value, key, item) => {
      return new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: "EUR"
      }).format(item.earning.total.toFixed(2));
    },
    isAdmin: false
  },
  {
    key: "paidPlay.total",
    label: "Paid plays",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-left align-middle",
    formatter: (value, key, item) => {
      return new Intl.NumberFormat(navigator.language).format(
        item.paidPlay.total
      );
    },
    isAdmin: false
  },
  {
    key: "organicPlay.total",
    label: "Organic plays",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-left align-middle",
    formatter: (value, key, item) => {
      return new Intl.NumberFormat(navigator.language).format(
        item.organicPlay.total
      );
    },
    isAdmin: false
  },
  {
    key: "paidInventory.total",
    label: "Inventory",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-left align-middle",
    formatter: (value, key, item) => {
      return new Intl.NumberFormat(navigator.language).format(
        item.paidInventory.total
      );
    },
    isAdmin: true
  },
  {
    key: "paidImpression.total",
    label: "Native ads impressions",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-left align-middle",
    formatter: (value, key, item) => {
      return new Intl.NumberFormat(navigator.language).format(
        item.paidImpression.total
      );
    },
    isAdmin: false
  },
  {
    key: "conversionRate.total",
    label: "Conversion rate",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-left align-middle",
    formatter: (value, key, item) => {
      return item.conversionRate.total + "%";
    },
    isAdmin: true
  },
  {
    key: "fillRate.total",
    label: "Fill rate",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-left align-middle",
    formatter: (value, key, item) => {
      return item.fillRate.total + "%";
    },
    isAdmin: true
  },
  {
    key: "rpmImpression.total",
    label: "Est. Impressions RPM",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-left align-middle",
    formatter: (value, key, item) => {
      return new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: "EUR"
      }).format(item.rpmImpression.total);
    },
    isAdmin: true
  },
  {
    key: "rpmPlay.total",
    label: "Est. Plays RPM",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-left align-middle",
    formatter: (value, key, item) => {
      return new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: "EUR"
      }).format(item.rpmPlay.total);
    },
    isAdmin: true
  }
];

export const tabsData = [
  {
    loading: true,
    title: "Est. Earnings",
    primaryStat: "-",
    previousCompare: "-",
    iconSVG: "assets/media/icons/svg/Shopping/Euro2.svg",
    iconSVGColor: "dark",
    theme: "light",
    selected: true,
    tooltip: {
      descriptionKey: "NATIVE_REVENUE.TOOLTIPS.EARNING"
    },
    renderFigure: function(x) {
      if (x !== "-" && x.hasOwnProperty("earning")) {
        return new Intl.NumberFormat(navigator.language, {
          style: "currency",
          currency: "EUR"
        }).format(x.earning.total);
      }
      return "-€";
    },
    normalizeDataTable: function(datas) {
      return normalizeRevenusDataTable(datas);
    },
    chartDataToDisplay: [
      {
        color: "#FF961D",
        label: "AudioHub",
        renderData: function(item) {
          return (item?.earning.classic || 0).toFixed(2);
        }
      },
      {
        color: "#FF4867",
        label: "Embed",
        renderData: function(item) {
          return (item?.earning.embed || 0).toFixed(2);
        }
      },
      {
        color: "#EC7063",
        label: "Discovery",
        renderData: function(item) {
          return (item?.earning.discovery || 0).toFixed(2);
        }
      }
    ],
    isAdmin: false
  },
  {
    loading: true,
    title: "Native inventory",
    primaryStat: "-",
    previousCompare: "-",
    iconSVG: "assets/media/icons/svg/Devices/TV1.svg",
    iconSVGColor: "success",
    cardClass: "bg-pi",
    selected: false,
    tooltip: {
      descriptionKey: "NATIVE_REVENUE.TOOLTIPS.INVENTORY"
    },
    renderFigure: function(x) {
      if (x !== "-" && x.hasOwnProperty("paidInventory")) {
        return new Intl.NumberFormat(navigator.language).format(
          x.paidInventory.total
        );
      }
      return "-";
    },
    chartDataToDisplay: [
      {
        color: "#FF961D",
        label: "AudioHub",
        renderData: function(item) {
          return item?.paidInventory.classic || 0;
        }
      },
      {
        color: "#FF4867",
        label: "Embed",
        renderData: function(item) {
          return item?.paidInventory.embed || 0;
        }
      },
      {
        color: "#EC7063",
        label: "Discovery",
        renderData: function(item) {
          return item?.paidInventory.discovery || 0;
        }
      }
    ],
    isAdmin: true
  },
  {
    loading: true,
    title: "Native ads impressions",
    primaryStat: "-",
    previousCompare: "-",
    iconSVG: "assets/media/icons/svg/Devices/TV1.svg",
    iconSVGColor: "primary",
    cardClass: "bg-pi",
    selected: false,
    tooltip: {
      descriptionKey: "NATIVE_REVENUE.TOOLTIPS.IMPRESSIONS"
    },
    renderFigure: function(x) {
      if (x !== "-" && x.hasOwnProperty("paidImpression")) {
        return new Intl.NumberFormat(navigator.language).format(
          x.paidImpression.total
        );
      }
      return "-";
    },
    normalizeDataTable: function(datas) {
      return normalizeRevenusDataTable(datas);
    },
    chartDataToDisplay: [
      {
        color: "#FF961D",
        label: "AudioHub",
        renderData: function(item) {
          return item?.paidImpression.classic || 0;
        }
      },
      {
        color: "#FF4867",
        label: "Embed",
        renderData: function(item) {
          return item?.paidImpression.embed || 0;
        }
      },
      {
        color: "#EC7063",
        label: "Discovery",
        renderData: function(item) {
          return item?.paidImpression.discovery || 0;
        }
      }
    ],
    isAdmin: false
  },
  {
    loading: true,
    title: "Conversion Rate",
    primaryStat: "-",
    previousCompare: "-",
    iconSVG: "assets/media/icons/svg/Communication/Check.svg",
    iconSVGColor: "warning",
    cardClass: "bg-pi",
    selected: false,
    isTimeChart: false,
    tooltip: {
      descriptionKey: "NATIVE_REVENUE.TOOLTIPS.CONVERSION_RATE"
    },
    renderFigure: function(x) {
      if (x !== "-" && x.hasOwnProperty("conversionRate")) {
        return (
          new Intl.NumberFormat(navigator.language).format(
            x.conversionRate.total
          ) + "%"
        );
      }
      return "-%";
    },
    chartOptions: {
      chartType: "area",
      stacked: true
    },
    chartDataToDisplay: [
      {
        color: "rgba(255,150,29,0.3)",
        label: "AudioHub",
        borderColor: "#FF961D",
        renderData: function(item) {
          return item?.conversionRate.classic || 0;
        }
      },
      {
        color: "rgba(255,72,103,0.2)",
        label: "Embed",
        borderColor: "#FF4867",
        renderData: function(item) {
          return item?.conversionRate.embed || 0;
        }
      },
      {
        color: "rgba(236, 112, 99,0.2)",
        label: "Discovery",
        borderColor: "#EC7063",
        renderData: function(item) {
          return item?.conversionRate.discovery || 0;
        }
      }
    ],
    isAdmin: true
  },
  {
    loading: true,
    title: "Fill Rate",
    primaryStat: "-",
    previousCompare: "-",
    iconSVG: "assets/media/icons/svg/Communication/Check.svg",
    iconSVGColor: "warning",
    cardClass: "bg-pi",
    selected: false,
    isTimeChart: false,
    tooltip: {
      descriptionKey: "NATIVE_REVENUE.TOOLTIPS.FILL_RATE"
    },
    renderFigure: function(x) {
      if (x !== "-" && x.hasOwnProperty("fillRate")) {
        return (
          new Intl.NumberFormat(navigator.language).format(x.fillRate.total) +
          "%"
        );
      }
      return "-%";
    },
    chartOptions: {
      chartType: "area",
      stacked: true
    },
    chartDataToDisplay: [
      {
        color: "rgba(255,150,29,0.3)",
        label: "AudioHub",
        borderColor: "#FF961D",
        renderData: function(item) {
          return item?.fillRate.classic || 0;
        }
      },
      {
        color: "rgba(255,72,103,0.2)",
        label: "Embed",
        borderColor: "#FF4867",
        renderData: function(item) {
          return item?.fillRate.embed || 0;
        }
      },
      {
        color: "rgba(236, 112, 99,0.2)",
        label: "Discovery",
        borderColor: "#EC7063",
        renderData: function(item) {
          return item?.fillRate.discovery || 0;
        }
      }
    ],
    isAdmin: true
  },
  {
    loading: true,
    title: "Impressions RPM",
    primaryStat: "-",
    previousCompare: "-",
    iconSVG: "assets/media/icons/svg/Shopping/PlayEuro.svg",
    iconSVGColor: "dark",
    theme: "light",
    selected: false,
    tooltip: {
      descriptionKey: "NATIVE_REVENUE.TOOLTIPS.IMPRESSION_RPM"
    },
    renderFigure: function(x) {
      if (x !== "-" && x.hasOwnProperty("rpmImpression")) {
        return new Intl.NumberFormat(navigator.language, {
          style: "currency",
          currency: "EUR"
        }).format(x.rpmImpression.total);
      }
      return "-€";
    },
    customOptions: {
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false
            },
            ticks: {
              maxRotation: 0,
              autoSkipPadding: 100
            },

            stacked: true
          }
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              drawBorder: false,
              borderDash: [4, 8]
            },
            ticks: {
              maxTicksLimit: 10,
              beginAtZero: true
            },
            stacked: false
          }
        ]
      }
    },
    chartOptions: {
      chartType: "area",
      stacked: true
    },
    chartDataToDisplay: [
      {
        color: "rgba(255,150,29,0.3)",
        label: "AudioHub",
        borderColor: "#FF961D",
        renderData: function(item) {
          return item?.rpmImpression.classic || 0;
        }
      },
      {
        color: "rgba(255,72,103,0.2)",
        label: "Embed",
        borderColor: "#FF4867",
        renderData: function(item) {
          return item?.rpmImpression.embed || 0;
        }
      },
      {
        color: "rgba(236, 112, 99,0.2)",
        label: "Discovery",
        borderColor: "#EC7063",
        renderData: function(item) {
          return item?.rpmImpression.discovery || 0;
        }
      }
    ],
    isAdmin: true
  },
  {
    loading: true,
    title: "Plays RPM",
    primaryStat: "-",
    previousCompare: "-",
    iconSVG: "assets/media/icons/svg/Shopping/PlayEuro.svg",
    iconSVGColor: "dark",
    theme: "light",
    selected: false,
    tooltip: {
      descriptionKey: "NATIVE_REVENUE.TOOLTIPS.PLAY_RPM"
    },
    renderFigure: function(x) {
      if (x !== "-" && x.hasOwnProperty("rpmPlay")) {
        return new Intl.NumberFormat(navigator.language, {
          style: "currency",
          currency: "EUR"
        }).format(x.rpmPlay.total);
      }
      return "-€";
    },
    customOptions: {
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false
            },
            ticks: {
              maxRotation: 0,
              autoSkipPadding: 100
            },

            stacked: true
          }
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              drawBorder: false,
              borderDash: [4, 8]
            },
            ticks: {
              maxTicksLimit: 10,
              beginAtZero: true
            },
            stacked: false
          }
        ]
      }
    },
    chartOptions: {
      chartType: "area",
      stacked: true
    },
    chartDataToDisplay: [
      {
        color: "rgba(255,150,29,0.3)",
        label: "AudioHub",
        borderColor: "#FF961D",
        renderData: function(item) {
          return item?.rpmPlay.classic || 0;
        }
      },
      {
        color: "rgba(255,72,103,0.2)",
        label: "Embed",
        borderColor: "#FF4867",
        renderData: function(item) {
          return item?.rpmPlay.embed || 0;
        }
      },
      {
        color: "rgba(236, 112, 99,0.2)",
        label: "Discovery",
        borderColor: "#EC7063",
        renderData: function(item) {
          return item?.rpmPlay.discovery || 0;
        }
      }
    ],
    isAdmin: true
  }
];

export const exportHeadersPrograms = [
  { company: "company" },
  { domain_name: "domain_name" },
  { host: "host" },
  { program_name: "program_name" },
  { program_brand: "program_brand" },
  { inventories: "inventories" },
  { "inventories:classic": "inventories : AudioHub" },
  { "inventories:embed": "inventories : Embed" },
  { "inventories:discovery": "inventories : Discovery" },
  { impressions: "impressions" },
  { "impressions:classic": "impressions : AudioHub" },
  { "impressions:embed": "impressions : Embed" },
  { "impressions:discovery": "impressions : Discovery" },
  { "plays:paid": "plays:paid" },
  { "plays:paid:classic": "plays:paid : AudioHub" },
  { "plays:paid:embed": "plays:paid : Embed" },
  { "plays:paid:discovery": "plays:paid : Discovery" },
  { "plays:organic": "plays:organic" },
  { "plays:organic:classic": "plays:organic : AudioHub" },
  { "plays:organic:embed": "plays:organic : Embed" },
  { "plays:organic:discovery": "plays:organic : Discovery" },
  { conversionrate: "impressions conversion rate" },
  { "conversionrate:classic": "impressions conversion rate : classic" },
  { "conversionrate:embed": "impressions conversion rate : embed" },
  {
    "conversionrate:discovery": "impressions conversion rate : discovery"
  },
  { fillrate: "fillrate" },
  { "fillrate:classic": "fillrate : AudioHub" },
  { "fillrate:embed": "fillrate : Embed" },
  { "fillrate:discovery": "fillrate : Discovery" },
  { earnings: "earnings" },
  { "earnings:classic": "earnings : AudioHub" },
  { "earnings:embed": "earnings : Embed" },
  { "earnings:discovery": "earnings : Discovery" },
  { "rpm:impressions": "impressions rpm" },
  { "rpm:impressions:classic": "impressions rpm : AudioHub" },
  { "rpm:impressions:embed": "impressions rpm : Embed" },
  { "rpm:impressions:discovery": "impressions rpm : Discovery" },
  { "rpm:plays": "plays rpm" },
  { "rpm:plays:classic": "plays rpm : AudioHub" },
  { "rpm:plays:embed": "plays rpm : Embed" },
  { "rpm:plays:discovery": "plays rpm : Discovery" }
];

export const exportPrograms = datas => {
  const exportData = [];

  for (const key in datas) {
    const {
      program,
      paidInventory,
      paidImpression,
      paidPlay,
      organicPlay,
      conversionRate,
      fillRate,
      earning,
      rpmImpression,
      rpmPlay
    } = datas[key];

    exportData.push({
      company: program.domain.company,
      domain_name: program.domain.name,
      host: program.domain.host,
      program_name: program.name,
      program_brand: program.brand,
      inventories: paidInventory.total,
      "inventories:classic": paidInventory.classic,
      "inventories:embed": paidInventory.embed,
      "inventories:discovery": paidInventory.discovery,
      impressions: paidImpression.total,
      "impressions:classic": paidImpression.classic,
      "impressions:embed": paidImpression.embed,
      "impressions:discovery": paidImpression.discovery,
      "plays:paid": paidPlay.total,
      "plays:paid:classic": paidPlay.classic,
      "plays:paid:embed": paidPlay.embed,
      "plays:paid:discovery": paidPlay.discovery,
      "plays:organic": organicPlay.total,
      "plays:organic:classic": organicPlay.classic,
      "plays:organic:embed": organicPlay.embed,
      "plays:organic:discovery": organicPlay.discovery,
      conversionrate: conversionRate.total.toFixed(2),
      "conversionrate:classic": conversionRate.classic.toFixed(2),
      "conversionrate:embed": conversionRate.embed.toFixed(2),
      "conversionrate:discovery": conversionRate.discovery.toFixed(2),
      fillrate: fillRate.total.toFixed(2),
      "fillrate:classic": fillRate.classic.toFixed(2),
      "fillrate:embed": fillRate.embed.toFixed(2),
      "fillrate:discovery": fillRate.discovery.toFixed(2),
      earnings: earning.total.toFixed(2),
      "earnings:classic": earning.classic.toFixed(2),
      "earnings:embed": earning.embed.toFixed(2),
      "earnings:discovery": earning.discovery.toFixed(2),
      "rpm:impressions": rpmImpression.total.toFixed(2),
      "rpm:impressions:classic": rpmImpression.classic.toFixed(2),
      "rpm:impressions:embed": rpmImpression.embed.toFixed(2),
      "rpm:impressions:discovery": rpmImpression.discovery.toFixed(2),
      "rpm:plays": rpmPlay.total.toFixed(2),
      "rpm:plays:classic": rpmPlay.classic.toFixed(2),
      "rpm:plays:embed": rpmPlay.embed.toFixed(2),
      "rpm:plays:discovery": rpmPlay.discovery.toFixed(2)
    });
  }
  return exportData;
};
