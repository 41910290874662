<template>
  <v-btn
    depressed
    color="darken-2 ml-5"
    :title="'Export ' + name"
    :loading="loading"
    v-on:click="exportCSV()"
    :disabled="disabled"
  >
    <v-icon>mdi-arrow-down-bold-circle-outline</v-icon>
  </v-btn>
</template>

<script>
import { download } from "../../common/csv.service";

export default {
  name: "ButtonExport",

  data() {
    return {
      loading: false
    };
  },

  props: {
    name: {
      type: String,
      required: true
    },
    headers: {
      type: Array,
      required: true,
      default: () => []
    },
    datas: {
      type: Array,
      required: true,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  methods: {
    exportCSV() {
      this.loading = true;
      const today = new Date();

      const currentDate =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

      const header = this.headers
        .map(properties => {
          if (typeof properties === "string") {
            return `"${properties}"`;
          } else if (typeof properties === "object") {
            return `"${Object.values(properties)[0]}"`;
          }
        })
        .join(",");

      const content = this.datas
        .map(row => {
          const rowCSV = [];
          for (const headerField of this.headers) {
            rowCSV.push(`"${row[Object.getOwnPropertyNames(headerField)[0]]}"`);
          }
          return rowCSV.join(",");
        })
        .join("\n");

      download(
        header + "\n" + content,
        `[${this.name}]_${currentDate}_export.csv`
      );

      this.loading = false;
    }
  }
};
</script>
