<template>
  <div>
    <v-row v-if="!permission">
      <b-col xs="12">
        <h2 class="text-left pl-5">Forbidden</h2>
      </b-col>
    </v-row>

    <FiltersTemplate
      v-if="permission"
      :inputFilters="inputFilters"
      apiSlug="revenue"
    />

    <AlertExpirePassword />
    <AlertRevenueDateStart v-bind:isDateWarning="isDateWarning" />

    <StatsPage
      v-if="permission"
      v-bind:APIslug="'revenue'"
      v-bind:tabsData="computedTabs"
      v-bind:displayChart="true"
      v-bind:displayCreate="false"
      v-bind:inputFilters="inputFilters"
      v-bind:maxQueryDays="maxQueryDays"
      class="kt-container"
    >
      <div class="row-cards my-3" v-if="isAdmin">
        <div class="card border-0">
          <div class="card-body text-right">
            <ButtonExport
              :name="'programs revenue'"
              :headers="headersPrograms"
              :datas="exportDataPrograms"
              :disabled="tableIsLoading"
            />

            <!-- <ButtonExport
              :name="'programs campaigns revenue'"
              :headers="headersProgramsCampaigns"
              :datas="exportDataProgramsCampaigns"
              :disabled="tableIsLoading"
            /> -->
          </div>
        </div>
      </div>

      <div v-if="isPaidCustomer || isAdmin" class="row row-cards">
        <Table
          v-bind:fields="computedTableFields"
          v-bind:loading="tableIsLoading"
          v-bind:items="programs"
          v-bind:rowClickable="true"
          v-bind:visibleCard="true"
        ></Table>
      </div>
    </StatsPage>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import StatsPage from "@/components/layout/StatsPage";
import { mapGetters } from "vuex";
import Table from "@/views/partials/content/cards/Table.vue";
import FiltersTemplate from "@/components/subheaders/filters/FiltersTemplate";
import { inputFilters } from "@/common/config/filters/revenue";
import AlertExpirePassword from "@/components/AlertExpirePassword";
import AlertRevenueDateStart from "@/components/AlertRevenueDateStart";

import {
  tableFields,
  tabsData,
  exportHeadersPrograms,
  exportPrograms
} from "@/common/config/dashboard/revenue";
import ButtonExport from "../../components/button/ButtonExport.vue";
import { getRevenuesPrograms } from "@/api/dashboard/revenues";
import ApiService from "@/common/api.service";
import { isDifferenceLessThanXDays } from "@/common/functions";

const DEFAULT_QUERY_MAX_DAYS = 62;

export default {
  name: "revenue",

  data() {
    return {
      timeout: 40000,
      programs: [],
      tableIsLoading: true,
      inputFilters: inputFilters,
      fields: tableFields,
      tabsData: tabsData,
      isDateWarning: false,
      maxQueryDays: DEFAULT_QUERY_MAX_DAYS
    };
  },

  computed: {
    ...mapGetters(["filters", "currentUser", "isAdmin", "isPaidCustomer"]),
    computedTabs() {
      return this.tabsData.filter(row => {
        if (row.isAdmin && !this.isAdmin) {
          return false;
        }
        return true;
      });
    },

    computedTableFields() {
      return this.fields.filter(row => {
        if (row.isAdmin && !this.isAdmin) {
          return false;
        }
        return true;
      });
    },

    permission() {
      if (this.isAdmin) {
        return true;
      } else if (this.currentUser.company) {
        return true;
      }
      return false;
    },

    headersPrograms() {
      return exportHeadersPrograms;
    },

    exportDataPrograms() {
      return exportPrograms(this.programs);
    }
  },
  components: {
    StatsPage,
    Table,
    FiltersTemplate,
    AlertExpirePassword,
    AlertRevenueDateStart,
    ButtonExport
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Native revenue",
        pageCategory: "Revenue"
      }
    ]);
  },
  watch: {
    filters() {
      this.isDateWarning =
        Date.parse(this.filters.dateRange[0]) < Date.parse("2022-08-01");

      try {
        this.periodeChecker();
      } catch (error) {
        this.tableIsLoading = false;
        return;
      }

      this.loadStats();
    }
  },
  methods: {
    loadStats() {
      this.tableIsLoading = true;
      this.programs = [];

      if (this.revenueProgramsCancelToken != null) {
        this.revenueProgramsCancelToken.cancel(
          ApiService.getCancelErrorMessage()
        );
      }

      this.revenueProgramsCancelToken = ApiService.getCancelToken();

      getRevenuesPrograms(
        this.axios,
        {},
        { ...this.getQueryFilters() },
        {
          cancelToken: this.revenueProgramsCancelToken.token,
          timeout: this.timeout
        }
      )
        .then(data => {
          if (Array.isArray(data)) {
            this.programs = data;
          }
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.tableIsLoading = false;
        });
    },

    periodeChecker() {
      const filters = this.getQueryFilters();

      if (
        !isDifferenceLessThanXDays(
          filters.start_date,
          filters.end_date,
          this.maxQueryDays
        )
      ) {
        throw new Error(
          `The period should not exceed ${this.maxQueryDays} days`
        );
      }
      return true;
    },

    getQueryFilters() {
      const filters = { ...this.filters };
      delete filters.dateRange;

      const periode = {
        start_date: this.filters.dateRange[0].substring(0, 10),
        end_date:
          new Date(this.filters.dateRange[1]) > new Date()
            ? new Date().getTime() / 1000
            : this.filters.dateRange[1].substring(0, 10)
      };

      return {
        ...filters,
        ...periode
      };
    }
  }
};
</script>
